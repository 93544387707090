import { type ReactElement, useRef, useState } from 'react'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import { type Control, Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatISO } from 'date-fns'
import {
  type Account,
  type OrderRequest,
  OrderRequestBasePriorityEnum
} from '@amici/myamici-api-client'
import useOrderRequest from '../hooks/useOrderRequest'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import MaConfirm from '../../common/components/MaConfirm'
import MaDatePicker from '../../common/components/MaDatePicker'
import MaCheckbox from '../../common/components/MaCheckbox'
import styles from '../assets/scss/OrderRequest.module.scss'
import FormControlTextArea from '../../common/components/FormControlTextArea'

const findAccount = (
  accountId?: string,
  accounts?: Account[]
): Account | undefined => accounts?.find(account => account.id === accountId)

interface OrderRequestEditSummaryModalProps {
  show: boolean
  orderRequest: OrderRequest
  onClose: () => void
}

function OrderRequestEditSummaryModal ({
  show,
  orderRequest,
  onClose
}: Readonly<OrderRequestEditSummaryModalProps>): ReactElement {
  const { t } = useTranslation()
  const { allAccounts, isLoading: isLoadingAllAccounts } = useAllAccounts()
  const { update, isUpdating } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToastMessage } = useToastNotification()
  const [modalFullyVisible, setModalFullyVisible] = useState(false)
  const modalRef = useRef<any>(null)

  const datepickerContainer = modalRef?.current?.dialog

  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      requestedBy: findAccount(
        orderRequest.requested_by?.id,
        allAccounts?.content
      )?.id,
      requiredByDate: orderRequest.required_by_date,
      priority: orderRequest.priority,
      confidential: orderRequest.confidential,
      reason: orderRequest.reason ?? ''
    }
  })

  const handleClose = (): void => {
    setModalFullyVisible(false)
    reset()
    onClose()
  }

  const handleUpdate = (): void => {
    const updatedOrderRequest: OrderRequest = {
      ...orderRequest,
      requested_by: requestedByAccount,
      required_by_date: getValues('requiredByDate'),
      priority: getValues('priority'),
      confidential: getValues('confidential'),
      reason: getValues('reason')?.trim()
    }

    void update(updatedOrderRequest)
      .then(() => {
        void refreshHistory()
      })
      .catch(() => {
        showToastMessage('danger', t('order_request.form.error'))
      })
      .finally(handleClose)
  }
  const requestedBy = watch('requestedBy')
  const requestedByAccount = findAccount(requestedBy, allAccounts?.content)
  const isBusy = isUpdating || isLoadingAllAccounts
  return (
    <MaConfirm
      ref={modalRef}
      size="lg"
      show={show}
      disabled={isBusy || !isValid || !requestedBy}
      title={t('order_request.edit_form.title')}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      onShow={() => {
        setModalFullyVisible(true)
      }}
      onClose={handleClose}
      onConfirm={handleUpdate}
    >
      <Form>
        <fieldset disabled={isBusy}>
          <Row className={styles['order-request-modal-row']}>
            <FormGroup as={Col} lg="4" controlId="requested-by">
              <Form.Label>
                {t('order_request.form.label.requested_by')}
              </Form.Label>
              <Controller
                name="requestedBy"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.requested_by')}
                    value={value}
                    onValueChange={value => {
                      onChange(value)
                    }}
                    placeholder={orderRequest.requested_by?.name}
                    isInvalid={!requestedByAccount}
                  >
                    {allAccounts?.content?.map(account => (
                      <MaSelectItem key={account.id} value={account.id}>
                        {account.name}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="required-by-date">
              <Form.Label>
                {t('order_request.form.label.date_required')}
              </Form.Label>
              {modalFullyVisible && (
                <MaDatePicker
                  container={datepickerContainer}
                  selected={
                    watch('requiredByDate')
                      ? new Date(getValues('requiredByDate') ?? '')
                      : undefined
                  }
                  onSelect={day => {
                    setValue(
                      'requiredByDate',
                      day ? formatISO(day, { representation: 'date' }) : ''
                    )
                  }}
                />
              )}
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="priority">
              <Form.Label>{t('order_request.form.label.priority')}</Form.Label>
              <Controller
                name="priority"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.priority')}
                    onValueChange={value => {
                      onChange(value)
                    }}
                    value={value}
                  >
                    {Object.keys(OrderRequestBasePriorityEnum).map(key => (
                      <MaSelectItem
                        key={key}
                        value={
                          OrderRequestBasePriorityEnum[
                            key as keyof typeof OrderRequestBasePriorityEnum
                          ]
                        }
                      >
                        {t(
                          `order_request.form.value.priority.${key.toLowerCase()}`
                        )}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>

          <Row className={styles['order-request-modal-row']}>
            <Controller
              name="confidential"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Form.Label className={styles.confidential}>
                  <MaCheckbox onCheckedChange={onChange} checked={value} />
                  {t('order_request.form.label.confidential')}
                </Form.Label>
              )}
            />
          </Row>

          <Row>
            <FormGroup as={Col} lg="12" controlId="reason">
              <FormControlTextArea
                label={t('order_request.form.label.reason')}
                value={watch('reason')}
                name="reason"
                required={false}
                maxLength={500}
                rows={5}
                control={control as unknown as Control}
              />
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestEditSummaryModal
