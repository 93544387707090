import { useState, type ReactElement } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useEnv } from '../../common/hooks/useEnv'
import usePurchaseOrder from '../hooks/usePurchaseOrder'
import useOrderItems from '../hooks/useOrderItems'
import useAccounts from '../../common/hooks/useAccounts'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import useValidateReorder from '../hooks/useValidateReorder'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import BuyAgainModal from '../components/BuyAgainModal'
import OrderHistory from '../components/OrderHistory'
import PurchaseOrderDeliveryInstructions from '../components/PurchaseOrderDeliveryInstructions'
import PurchaseOrderDetails from '../components/PurchaseOrderDetails'
import PurchaseOrderHeader from '../components/PurchaseOrderHeader'
import PurchaseOrderOrderItems from '../components/PurchaseOrderOrderItems'
import PurchaseOrderReference from '../components/PurchaseOrderReference'
import PurchaseOrderSummaryOfCharges from '../components/PurchaseOrderSummaryOfCharges'
import styles from '../assets/scss/PurchaseOrder.module.scss'

function PurchaseOrderActions ({
  isValidatingReorder,
  onAddInvoice,
  onPrintOrder,
  onBuyAgain
}: Readonly<{
  isValidatingReorder: boolean
  onAddInvoice: () => void
  onPrintOrder: () => void
  onBuyAgain: () => void
}>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { isFinanceUser, hasFinanceModule } =
    usePurchasingPermissions(activeAccount)

  return (
    <MaActionMenu aria-label={t('purchase_order.actions.label')}>
      <MaActionMenuItem onClick={onPrintOrder}>
        {t('purchase_order.action.print_order')}
      </MaActionMenuItem>
      {isFinanceUser && hasFinanceModule && (
        <MaActionMenuItem onClick={onAddInvoice}>
          {t('purchase_order.action.add_invoice')}
        </MaActionMenuItem>
      )}
      <MaActionMenuItem onClick={onBuyAgain} disabled={isValidatingReorder}>
        {t('purchase_order.action.buy_again')}
      </MaActionMenuItem>
    </MaActionMenu>
  )
}

function PurchaseOrder (): ReactElement {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { t } = useTranslation()
  const { orderId } = useParams()

  const { isLoading, error, data: order } = usePurchaseOrder(orderId)
  const { data: validateReorderData, isLoading: isValidatingReorder } =
    useValidateReorder(order?.id ?? '')
  const { data: lineItems } = useOrderItems(orderId ?? '')
  const [showBuyAgainModal, setShowBuyAgainModal] = useState(false)

  const showOrder = !isLoading && !error && order
  const showError = !isLoading && error

  const ma3OrderUrl = `${REACT_APP_MA3_BASE_URL}/Order/OrderView.aspx?OrderId=${order?.id}`
  const addInvoiceUrl = `${REACT_APP_MA3_BASE_URL}/Invoice/InvoicePage1.aspx?OrderId=${order?.id}`
  const printOrderUrl = `${REACT_APP_MA3_BASE_URL}/Order/OrderPDF.aspx?OrderId=${order?.id}`

  const handleAddInvoice = (): void => {
    globalThis.open(addInvoiceUrl, '_blank')
  }

  const handlePrintOrder = (): void => {
    globalThis.open(printOrderUrl, '_blank')
  }

  return (
    <Container fluid="auto" className={classNames('ma-page')}>
      <PurchaseOrderHeader />

      {isLoading && !error && <LoadingSpinner />}

      {showError && (
        <p className={styles.error}>
          {t('purchase_order.not_found.sorry')}{' '}
          {t('purchase_order.not_found.message', { order_id: orderId })}
        </p>
      )}

      {showOrder && (
        <>
          <div className={styles['order-title-row']}>
            <h2 className={styles['order-title']}>
              <PurchaseOrderReference order={order} link={ma3OrderUrl} />{' '}
              {order?.confidential && (
                <span className={styles.confidential}>
                  {t('purchase_order.title.confidential')}
                </span>
              )}
            </h2>

            <PurchaseOrderActions
              isValidatingReorder={isValidatingReorder}
              onAddInvoice={handleAddInvoice}
              onPrintOrder={handlePrintOrder}
              onBuyAgain={() => {
                setShowBuyAgainModal(true)
              }}
            />
          </div>

          <div className={styles['panels-grid']}>
            <div className={styles['panels-row']}>
              <PurchaseOrderDetails
                className={styles['order-details']}
                order={order}
              />

              <PurchaseOrderSummaryOfCharges
                className={styles['summary-of-charges']}
                order={order}
              />
            </div>

            <PurchaseOrderDeliveryInstructions order={order} />

            <PurchaseOrderOrderItems lineItems={lineItems?.content} />

            <OrderHistory order={order} lineItems={lineItems} />

            {/* TODO: extract into its own component */}
            {/* <MaPanel className={styles['additional-details']}>
              <MaPanel.Body />
            </MaPanel> */}
          </div>
        </>
      )}

      {order && validateReorderData && (
        <BuyAgainModal
          order={order}
          successfulOrderItems={validateReorderData.successful_line_items ?? []}
          unsuccessfulOrderItems={
            validateReorderData.unsuccessful_line_items ?? []
          }
          show={showBuyAgainModal}
          onClose={() => {
            setShowBuyAgainModal(false)
          }}
        />
      )}
    </Container>
  )
}

export default PurchaseOrder
