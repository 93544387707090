import { type ReactElement } from 'react'
import { type BasketLineItem } from '@amici/myamici-api-client'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useOrderRequestSummary from '../../order-requests/hooks/useOrderRequestSummary'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/OrderRequestSummary.module.scss'

interface OrderRequestSummaryProps {
  selectedItems: BasketLineItem[]
  onCreateRequest: (lineItems: BasketLineItem[]) => void
}
function OrderRequestSummary ({
  selectedItems,
  onCreateRequest
}: Readonly<OrderRequestSummaryProps>): ReactElement {
  const { t } = useTranslation()

  const noSelectedItems = selectedItems.length < 1

  const {
    suppliers,
    currencies,
    getSupplierCurrencies,
    getSupplierItems,
    getSupplierTotal,
    getSupplierSurcharges,
    getTotal
  } = useOrderRequestSummary(selectedItems ?? [])

  return (
    <MaPanel className={styles['order-request']}>
      <MaPanel.Header className={styles.header}>
        <h5>{t('basket.request_summary.title')}</h5>
      </MaPanel.Header>

      <MaPanel.Body className={styles.body}>
        {noSelectedItems && (
          <p>{t('basket.request_summary.no_selected_items')}</p>
        )}

        {suppliers.map(supplier => (
          <section key={supplier.id} className={styles.supplier}>
            <h6>{supplier.name}</h6>

            {getSupplierCurrencies(supplier).map(currency => (
              <p key={`${supplier.id}-${currency}-items`}>
                <span>
                  {t('basket.request_summary.item', {
                    count: getSupplierItems(supplier, currency).length
                  })}
                </span>

                <span>
                  {t('common.price', {
                    price: getSupplierTotal(supplier, currency),
                    currency
                  })}
                </span>
              </p>
            ))}

            {getSupplierCurrencies(supplier).map((currency, index) => (
              <p key={`${supplier.id}-${currency}-delivery`}>
                <span>
                  {index === 0 && t('basket.request_summary.delivery_charge')}
                </span>

                <span>
                  {getSupplierSurcharges(supplier, currency) < 0
                    ? t('basket.request_summary.delivery_charge.unknown')
                    : getSupplierSurcharges(supplier, currency) === 0
                      ? t('basket.request_summary.delivery_charge.free')
                      : t('common.price', {
                        price: getSupplierSurcharges(supplier, currency),
                        currency
                      })}
                </span>
              </p>
            ))}
          </section>
        ))}
      </MaPanel.Body>

      <MaPanel.Footer className={styles.footer}>
        <div className={styles.total}>
          {currencies.map((currency, index) => (
            <p key={`${currency}-total`}>
              <span>{index === 0 && t('basket.request_summary.total')}</span>

              <span>
                {t('common.price', { price: getTotal(currency), currency })}
              </span>
            </p>
          ))}
        </div>

        <Button
          variant="primary"
          className={classNames('rounded', styles.create)}
          disabled={noSelectedItems}
          onClick={() => {
            onCreateRequest(selectedItems)
          }}
        >
          {t('basket.request_summary.create_request')}
        </Button>
      </MaPanel.Footer>
    </MaPanel>
  )
}

export default OrderRequestSummary
